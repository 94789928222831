define('xamoom-admin/services/progress-indicator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({

    /**
     * Calls Pace's restart() function.
     * @tested
     */
    start: function start() {
      window.Pace.restart();
    },


    /**
     * Calls Pace's stop() function.
     * @tested
     */
    stop: function stop() {
      window.Pace.stop();
    }
  });
});