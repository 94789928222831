define('xamoom-admin/controllers/sessions/password/set', ['exports', 'xamoom-admin/config/environment', 'xamoom-admin/mixins/sha512-generator-mixin'], function (exports, _environment, _sha512GeneratorMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  var computed = Ember.computed;
  var get = Ember.get;
  var getProperties = Ember.getProperties;
  var setProperties = Ember.setProperties;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend(_sha512GeneratorMixin.default, {

    /**
     * ember-ajax service.
     * {Ember.Service}
     * @tested
     */
    ajax: service(),

    /**
     * Query params to expect.
     * {[String]}
     * @tested
     */
    queryParams: ['resetToken'],

    /**
       * Flag determining there is some process going on.
       * {Boolean}
       * @tested
       */
    isProcessing: null,

    /**
       * New password to be set.
       * {String}
       * @tested
       */
    newPassword: null,

    /**
       * Reset token parsed from current URL.
       * {String}
       * @tested
       */
    resetToken: null,

    /**
       * Error message.
       * {String}
       * @tested
       */
    errorMessage: null,

    /**
       * Flag determining the password reset action has been stated to the backend successfully.
       * {Boolean}
       * @tested
       */
    passwordSetSucceeded: null,

    /**
     * Flag indicating the reset button is disabled, which means:
     * - either a process is already going on
     * OR
     * - new password is incorrect
     * OR
     * - reset token is incorrect (not present)
     * {Boolean}
     * @tested
     */
    isSetButtonDisabled: or('isProcessing', 'isNewPasswordEmpty', 'isResetTokenEmpty'),

    /**
     * Flag indicating the resetToken property is incorrect, which means:
     * - null
     * - or empty string
     * {Boolean}
     * @tested
     */
    isResetTokenEmpty: computed('resetToken', function () {
      var resetToken = get(this, 'resetToken');

      return isEmpty(resetToken) || resetToken.trim().length < 1;
    }),

    /**
     * Flag indicating the newPassword property is incorrect, which means:
     * - null
     * - or empty string
     * {Boolean}
     * @tested
     */
    isNewPasswordEmpty: computed('newPassword', function () {
      var newPassword = get(this, 'newPassword');

      return isEmpty(newPassword) || newPassword.trim().length < 1;
    }),

    actions: {
      /**
       * Delegates to doSetNewPassword()
       * @see doSetNewPassword()
       * @tested
       */
      setNewPassword: function setNewPassword() {
        this.doSetNewPassword();
      }
    },

    /**
     * Creates new record 'passwordreset' using session token read from current URL and new password.
     * This results in backend resetting old password to new user password.
     * Terminates with null if new password is incorrect or reset token is missing.
     * @returns {null|Promise.<DS.Model>} Passwordreset model
     * @tested
     */
    doSetNewPassword: function doSetNewPassword() {
      var _this = this;

      if (get(this, 'isNewPasswordEmpty')) {
        setProperties(this, {
          isProcessing: false,
          errorMessage: 'Please enter your new password.'
        });
        return null;
      }

      if (get(this, 'isResetTokenEmpty')) {
        setProperties(this, {
          isProcessing: false,
          errorMessage: 'Token seems to be missing, click on link you received in your email once again please.'
        });
        return null;
      }

      setProperties(this, {
        isProcessing: true,
        passwordSetSucceeded: false,
        errorMessage: null
      });

      var _getProperties = getProperties(this, 'resetToken', 'newPassword', 'serverTokenEndpoint'),
          resetToken = _getProperties.resetToken,
          newPassword = _getProperties.newPassword,
          serverTokenEndpoint = _getProperties.serverTokenEndpoint;

      var newPasswordHashed = this.generateSHA512(newPassword);

      var data = {
        data: {
          attributes: {
            'reset-token': resetToken,
            password: newPasswordHashed
          },
          type: 'passwordreset'
        }
      };

      var options = {
        data: JSON.stringify(data),
        dataType: 'json',
        accept: 'application/vnd.api+json',
        headers: {
          'Content-Type': 'application/vnd.api+json'
        }
      };

      // include the dev key if development mode is on
      if (get(this, 'devMode') && isPresent(get(this, 'devKey'))) {
        options.headers['X-Devkey'] = get(this, 'devKey');
      }

      return get(this, 'ajax').post(serverTokenEndpoint + '/passwordreset', options).then(function () {
        setProperties(_this, {
          isProcessing: false,
          passwordSetSucceeded: true,
          errorMessage: null
        });
      }).catch(function (error) {
        var message = 'Invalid reset token.';
        if (isPresent(get(error, 'errors.firstObject.detail'))) {
          message = get(error, 'errors.firstObject.detail');
        }
        setProperties(_this, {
          isProcessing: false,
          errorMessage: message
        });
      });
    },


    /**
       * Development mode flag imported from configuration
       * @tested
       */
    devMode: _environment.default.APP.DEV_MODE,

    /**
           * Development key value imported from configuration
           * @tested
           */
    devKey: _environment.default.APP.X_DEV_KEY,

    /**
           * xamoom authentication endpoint
           * @tested
           */
    serverTokenEndpoint: _environment.default.APP.BASE_URL + '/' + _environment.default.APP.AUTH_NAMESPACE

  });
});