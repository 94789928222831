define('xamoom-admin/models/user-role', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = _emberData.default.Model.extend({
    /**
     * User role name.
     */
    name: _emberData.default.attr('string'),

    /**
     * Determines whether user role is a xamoom-admin user role.
     * @returns true if user-role ID is 0, otherwise false.
     * @tested
     */
    isXamoomAdminRole: computed('id', function () {
      var userRoleId = get(this, 'id');

      if (isNone(userRoleId)) {
        return false;
      }

      return parseInt(userRoleId, 10) === 0;
    }),

    /**
     * Determines whether user role is an admin user role.
     * @returns true if user-role ID is 1, otherwise false.
     * @tested
     */
    isAdminRole: computed('id', function () {
      var userRoleId = get(this, 'id');

      if (isNone(userRoleId)) {
        return false;
      }

      return parseInt(userRoleId, 10) === 1;
    }),

    /**
     * Determines whether user role is a (normal) user role.
     * @returns true if user-role ID is 2, otherwise false.
     * @tested
     */
    isUserRole: computed('id', function () {
      var userRoleId = get(this, 'id');

      if (isNone(userRoleId)) {
        return false;
      }

      return parseInt(userRoleId, 10) === 2;
    }),

    /**
     * Determines whether user role is a xamoom shadow admin user role.
     * @returns true if user-role ID is 3, otherwise false.
     * @tested
     */
    isXamoomShadowAdminRole: computed('id', function () {
      var userRoleId = get(this, 'id');

      if (isNone(userRoleId)) {
        return false;
      }

      return parseInt(userRoleId, 10) === 3;
    }),

    /**
     * Determines whether user role is a restricted user role.
     * @returns true if user-role ID is 4, otherwise false.
     * @tested
     */
    isRestrictedRole: computed('id', function () {
      var userRoleId = get(this, 'id');

      if (isNone(userRoleId)) {
        return false;
      }

      return parseInt(userRoleId, 10) === 4;
    })
  });
});