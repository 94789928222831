define('xamoom-admin/adapters/system', ['exports', 'xamoom-admin/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = _application.default.extend({

    cloneSystem: service('clone-system'),
    /**
       * this is used to pass system id on cloning
       * @param {*} store
       * @param {*} type
       * @param {*} snapshot
       * @override
       */
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      var systemIdToCloneFrom = get(this, 'cloneSystem.systemId');
      if (systemIdToCloneFrom) {
        url += '?system-id=' + systemIdToCloneFrom;
      }

      return this.ajax(url, 'POST', { data: data });
    }
  });
});