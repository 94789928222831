define('xamoom-admin/routes/users/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'xamoom-admin/mixins/model-preloader-mixin'], function (exports, _authenticatedRouteMixin, _modelPreloaderMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var once = Ember.run.once;
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _modelPreloaderMixin.default, {

    /**
     * Model-preloader-related specification determining models
     * (user-roles, user-statuses) required by the route and controller
     * (users/index) whose properties should be set after the models get loaded.
     * @tested
     */
    preloadConfig: {
      controllerName: 'users/index',
      preloadedModels: [{
        modelName: 'userRole',
        mappedTo: 'userRoles'
      }, {
        modelName: 'userStatus',
        mappedTo: 'userStatuses'
      }]
    },

    /**
     * Delegates to AuthenticatedRouteMixin.beforeModel() to check user authentication
     * status and calls ModelPreloaderMixin.loadModelsAndSetControllerProperties()
     * to invoke setting (model) properties upon a controller.
     * @param {Transition} transition
     * @returns {Promise} result of the loadModelsAndSetControllerProperties() call
     * @tested
     */
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      return this.loadModelsAndSetControllerProperties();
    },


    /**
     * Returns an empty array as its model because real model instances
     * will be loaded via 'filter' action triggered from setupController().
     * @see setupController()
     * @returns {Array} empty array
     * @tested
     */
    model: function model() {
      return [];
    },


    /**
     * Sends an action called 'filter' on controller setup.
     * @param {Ember.Controller} controller
     * @param {DS.Model} model
     * @tested
     */
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      once(function () {
        controller.send('filter');
      });
    }
  });
});