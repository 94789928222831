define('xamoom-admin/routes/users/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'xamoom-admin/mixins/model-preloader-mixin'], function (exports, _authenticatedRouteMixin, _modelPreloaderMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEqual = Ember.isEqual;
  var get = Ember.get;
  var set = Ember.set;
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _modelPreloaderMixin.default, {

    /**
     * Model-preloader-related specification determining models
     * (user-roles, user-statuses) required by the route and controller
     * (users/edit) whose properties should be set after models are loaded.
     * @tested
     */
    preloadConfig: {
      controllerName: 'users/edit',
      preloadedModels: [{
        modelName: 'userRole',
        mappedTo: 'userRoles'
      }, {
        modelName: 'userStatus',
        mappedTo: 'userStatuses'
      }]
    },

    /**
     * Delegates to AuthenticatedRouteMixin.beforeModel() to check user authentication
     * status and calls ModelPreloaderMixin.loadModelsAndSetControllerProperties()
     * to invoke setting (model) properties upon a controller.
     * @param {Transition} transition
     * @returns {Promise} result of the loadModelsAndSetControllerProperties() call
     * @tested
     */
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (this.isAttemptToEditSelf(get(transition, 'params'))) {
        this.transitionTo('profile');
      }

      return this.loadModelsAndSetControllerProperties();
    },


    /**
     * Returns a user instance looked up via parameter 'user_id'.
     * @returns {DS.Model}
     * @tested
     */
    model: function model(params) {
      return this.store.find('user', params.user_id);
    },


    actions: {
      /**
       * Sets an error property upon the application controller.
       * In case the model could not be found (code 500), we redirect to 'users.index route'.
       * @param error
       * @returns {boolean} false to prevent action bubbling
       * Note: not tested
       */
      error: function error(_error) {
        // propagate
        set(this.controllerFor('application'), 'error', _error);

        try {
          // if model hasn't been found, redirect to systems.index
          if (_error.errors[0].code === 500) {
            this.transitionTo('users.index');
          }
        } catch (e) {}
        // skip, no need to catch anything


        // do not propagate further
        return false;
      }
    },

    /**
     * Distinguishes whether a currently logged-in user id is the same as the id
     * of a user who the currently logged-in user tries to edit.
     * Logged-in users should use their profile page to update themselves, not the users.edit resource!
     * @param {Object} params
     * @returns {boolean} false if current user tries to update someone else, otherwise true
     * @tested
     */
    isAttemptToEditSelf: function isAttemptToEditSelf(params) {
      var currentUserId = get(this, 'session.data.authenticated.data.id');
      var updatedUserId = params['users.edit'].user_id;

      return isEqual(currentUserId, updatedUserId);
    }
  });
});