define('xamoom-admin/routes/markers/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var once = Ember.run.once;
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {

    /**
     * Delegates to AuthenticatedRouteMixin.beforeModel() to check user authentication status.
     * @param {Transition} transition
     * @returns {*|Promise} result of super() call
     * @tested
     */
    beforeModel: function beforeModel(transition) {
      return this._super(transition);
    },


    /**
     * Returns an empty array as its model because real model instances
     * will be loaded via 'filter' action triggered from setupController().
     * @see setupController()
     * @returns {Array} empty array
     * @tested
     */
    model: function model() {
      return [];
    },


    /**
     * Sends an action called 'filter' on controller setup.
     * @param {Ember.Controller} controller
     * @param {DS.Model} model
     * @tested
     */
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      once(function () {
        controller.send('filter');
      });
    }
  });
});