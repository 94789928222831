define('xamoom-admin/services/clone-system', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    /**
     * systemId
     */
    systemId: null
  });
});