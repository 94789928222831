define('xamoom-admin/models/marker', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    /**
     * Eddystone URL associated with the marker.
     * @tested
     */
    eddystoneUrl: _emberData.default.attr('string'),

    /**
     * iBeacon (major ID) associated with the marker.
     * @tested
     */
    ibeaconMajor: _emberData.default.attr('string'),

    /**
     * iBeacon (minor ID) associated with the marker.
     * @tested
     */
    ibeaconMinor: _emberData.default.attr('string'),

    /**
     * iBeacon (region UID) associated with the marker.
     * @tested
     */
    ibeaconRegionUid: _emberData.default.attr('string'),

    /**
     * Flag determining whether the marker is produced.
     * @tested
     */
    isProduced: _emberData.default.attr('boolean'),

    /**
     * NFC associated with the marker.
     * @tested
     */
    nfc: _emberData.default.attr('string'),

    /**
     * QR code associated with the marker.
     * @tested
     */
    qr: _emberData.default.attr('string'),

    system: _emberData.default.belongsTo('system'),

    /**
     * Relationship to the model: spot.
     * A marker can belongs to / can be associated with a spot.
     * marker -> 1 spot
     */
    spot: _emberData.default.belongsTo('spot', {
      async: true
    })
  });
});