define('xamoom-admin/models/system-type', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = _model.default.extend({
    /**
     * System-type name.
     * @tested
     */
    name: (0, _attr.default)('string'),

    /**
     * Determines whether system-type is a type: free.
     * @returns true if system-type ID is 0, otherwise false.
     * @tested
     */
    isFree: computed('id', function () {
      var systemTypeId = get(this, 'id');

      if (isNone(systemTypeId)) {
        return false;
      }

      return parseInt(systemTypeId, 10) === 0;
    }),

    /**
     * Determines whether system-type is a type: small.
     * @returns true if system-type ID is 1, otherwise false.
     * @tested
     */
    isSmall: computed('id', function () {
      var systemTypeId = get(this, 'id');

      if (isNone(systemTypeId)) {
        return false;
      }

      return parseInt(systemTypeId, 10) === 1;
    }),

    /**
     * Determines whether system-type is a type: standard.
     * @returns true if system-type ID is 2, otherwise false.
     * @tested
     */
    isStandard: computed('id', function () {
      var systemTypeId = get(this, 'id');

      if (isNone(systemTypeId)) {
        return false;
      }

      return parseInt(systemTypeId, 10) === 2;
    }),

    /**
     * Determines whether system-type is a type: standard.
     * @returns true if system-type ID is 3, otherwise false.
     * @tested
     */
    isEnterprise: computed('id', function () {
      var systemTypeId = get(this, 'id');

      if (isNone(systemTypeId)) {
        return false;
      }

      return parseInt(systemTypeId, 10) === 3;
    })
  });
});