define('xamoom-admin/mixins/sha512-generator-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    /**
     * Generates SHA512 of the given text
     * @param text
     * @returns {string} argument's SHA512
     * @tested
     */
    generateSHA512: function generateSHA512(text) {
      return sha512(text);
    }
  });
});