define('xamoom-admin/helpers/id-shortener', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.idShortener = idShortener;
  var helper = Ember.Helper.helper;
  var isNone = Ember.isNone;


  /**
   * Returns first 2 characters and last 2 characters of the string given as an argument,
   * having 2 dots ("..") between these 2 couples of characters.
   * If a string passed is shorter than 6 characters, it returns the same string, because
   * it makes no sense to shorten something which is already short enough :)
   * @example
   * "abcdefgh" (length 7) -> "ab..gh"
   * "abcdef" (length 6) -> "abcdef"
   * "ab" (length 2) -> "ab"
   * @param [String] params - array with a string at position 1 to be shortened
   * @returns {String} shortened version of the string passed or a an empty string on no arguments given
   */
  function idShortener(params /* , hash */) {
    if (isNone(params) || isNone(params[0])) {
      console.error('id-shortener helper used passing no parameters!');
      return '';
    }

    var id = params[0];

    if (id.length <= 6) {
      // return full id, no need to put "." instead of 1 symbol
      return id;
    }

    return id.slice(0, 2) + '..' + id.substr(id.length - 2);
  }

  exports.default = helper(idShortener);
});