define('xamoom-admin/mixins/processing-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({

    /**
     * Progress-bar (progress-indicator) service.
     */
    progressIndicator: service('progress-indicator'),

    /**
     * Flag that turns into true if a process is being executed, otherwise it stays false.
     */
    isProcessing: false,

    /**
     * Sets isProcessing to true and initializes a progress bar.
     * @tested
     */
    indicateProcessStart: function indicateProcessStart() {
      set(this, 'isProcessing', true);
      get(this, 'progressIndicator').start();
    },


    /**
     * Sets isProcessing to false and terminates a progress bar.
     * @tested
     */
    indicateProcessCompletion: function indicateProcessCompletion() {
      set(this, 'isProcessing', false);
      get(this, 'progressIndicator').stop();
    }
  });
});