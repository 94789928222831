define('xamoom-admin/controllers/sessions/logout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  var get = Ember.get;
  exports.default = Controller.extend({

    /**
     * Session service
     */
    session: service('session'),

    /**
     * @tested
     */
    actions: {
      /**
       * Calls invalidateSession().
       * Received the action from the respective route's beforeModel(),
       * @see app/routes/sessions/logout.js
       * @tested
       */
      logout: function logout() {
        this.invalidateSession();
      }
    },

    /**
     * Delegates a session invalidation to the session service.
     * @tested
     */
    invalidateSession: function invalidateSession() {
      get(this, 'session').invalidate();
      delete get(this, 'session.data').token;
    }
  });
});