define('xamoom-admin/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({

    /**
     * Session service
     * @tested
     */
    session: service(),

    /**
     * Performs a transition to systems.index in a case of already authenticated user.
     * Otherwise it redirects to sessions.login.
     * @tested
     */
    redirect: function redirect() /* model, transition */{
      if (get(this, 'session.isAuthenticated')) {
        this.transitionTo('systems.index');
      } else {
        this.transitionTo('sessions.login');
      }
    }
  });
});