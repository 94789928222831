define('xamoom-admin/controllers/sessions/password/reset', ['exports', 'xamoom-admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  var computed = Ember.computed;
  var get = Ember.get;
  var getProperties = Ember.getProperties;
  var setProperties = Ember.setProperties;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({

    /**
     * ember-ajax service.
     * {Ember.Service}
     * @tested
     */
    ajax: service('ajax'),

    /**
     * Flag determining there is some process going on.
     * {Boolean}
     * @tested
     */
    isProcessing: null,

    /**
     * Email address to which a password reset email should be sent.
     * {String}
     * @tested
     */
    email: null,

    /**
     * Error message.
     * {String}
     * @tested
     */
    errorMessage: null,

    /**
     * Flag determining the password reset action has been stated to the backend successfully.
     * {Boolean}
     * @tested
     */
    passwordResetSucceeded: null,

    /**
     * Flag indicating the reset button is disabled, which means:
     * - either a process is already going on
     * OR
     * - email value is not set correctly
     * {Boolean}
     * @tested
     */
    isResetButtonDisabled: or('isProcessing', 'isEmailEmpty'),

    /**
     * Flag indicating the email address property is incorrect, which means:
     * - null
     * - or empty string
     * {Boolean}
     * @tested
     */
    isEmailEmpty: computed('email', function () {
      var email = get(this, 'email');

      return isEmpty(email) || email.trim().length < 1;
    }),

    actions: {
      /**
       * Delegates to doReset()
       * @see doReset()
       * @tested
       */
      reset: function reset() {
        this.doReset();
      }
    },

    /**
     * Queries record 'passwordreset' by user email in order to cause backend send an email with a link containing a session token.
     * This session token is then later used in 'sessions.password.set' route/controller.
     * Terminates with null if email is not set or empty.
     * @returns {null|Promise.<DS.Model>} Passwordreset model
     * @tested
     */
    doReset: function doReset() {
      var _this = this;

      if (get(this, 'isEmailEmpty')) {
        setProperties(this, {
          isProcessing: false,
          errorMessage: 'Please enter your email address.'
        });
        return null;
      }

      setProperties(this, {
        isProcessing: true,
        passwordResetSucceeded: false,
        errorMessage: null
      });

      var _getProperties = getProperties(this, 'email', 'serverTokenEndpoint'),
          email = _getProperties.email,
          serverTokenEndpoint = _getProperties.serverTokenEndpoint;

      var emailProp = window.encodeURIComponent(email);

      var options = {
        dataType: 'json',
        accept: 'application/vnd.api+json',
        headers: {
          'Content-Type': 'application/vnd.api+json'
        }
      };

      // include the dev key if development mode is on
      if (get(this, 'devMode') && isPresent(get(this, 'devKey'))) {
        options.headers['X-Devkey'] = get(this, 'devKey');
      }

      return get(this, 'ajax').request(serverTokenEndpoint + '/passwordreset?email=' + emailProp, options).then(function () {
        setProperties(_this, {
          isProcessing: false,
          passwordResetSucceeded: true,
          errorMessage: null
        });
      }).catch(function (error) {
        var message = 'Email address is no valid or an unspecified problem occurred.';

        if (isPresent(get(error, 'errors.firstObject.detail'))) {
          message = get(error, 'errors.firstObject.detail');
        }

        setProperties(_this, {
          isProcessing: false,
          errorMessage: message
        });
      });
    },


    /**
     * Development mode flag imported from configuration
     * @tested
     */
    devMode: _environment.default.APP.DEV_MODE,

    /**
       * Development key value imported from configuration
       * @tested
       */
    devKey: _environment.default.APP.X_DEV_KEY,

    /**
       * xamoom authentication endpoint
       * @tested
       */
    serverTokenEndpoint: _environment.default.APP.BASE_URL + '/' + _environment.default.APP.AUTH_NAMESPACE

  });
});