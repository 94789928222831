define('xamoom-admin/components/user-form', ['exports', 'xamoom-admin/mixins/sha512-generator-mixin'], function (exports, _sha512GeneratorMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var isBlank = Ember.isBlank;
  var typeOf = Ember.typeOf;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend(_sha512GeneratorMixin.default, {

    // services
    /**
     * Store service
     * @tested
     */
    store: service(),

    /**
     * Indicates user intention to modify his password.
     * False by default.
     * {boolean}
     * @tested
     */
    isPasswordChangeRequired: false,

    // computed properties
    /**
     * Indicates ability of the submit button.
     * The submit button is enabled (clickable) only in a case:
     * - no other process is being run.
     * {boolean}
     * @tested
     */
    isSubmitButtonDisabled: computed('isProcessing', 'isPasswordInvalid', 'isPasswordChangeRequired', function () {
      var isProcessing = get(this, 'isProcessing');
      var isPasswordInvalid = get(this, 'isPasswordInvalid');
      var isPasswordChangeRequired = get(this, 'isPasswordChangeRequired');

      if (isProcessing) {
        return true; // always disabled as long as there is a process going on
      }

      return isPasswordChangeRequired && isPasswordInvalid;
    }),

    /**
     * Flag detecting the password validity.
     * The password is valid only if:
     *  - its trimmed version is at least 8 characters long
     *  - it contains at least 1 digit
     * {boolean}
     * @tested
     */
    isPasswordInvalid: computed('password', function () {
      var password = get(this, 'password');

      return isNone(password) || password.trim().length < 8 || password.search(/[0-9]/) === -1;
    }),

    actions: {
      /**
       * Sends action "confirmChanges".
       * @tested
       */
      submit: function submit() {
        this.setUserPasswordUponModel();

        this.sendAction('confirmChanges');
      },


      /**
       * Returns a promise consumed by ember-power-select offering
       * a collection of systems assign user to.
       * In a case of:
       *  - incorrect arguments
       *  - too short (<3) string
       * returns an empty array.
       * @param {string} systemName string used to perform a lookup by 'system name'
       * @returns {Array|Ember.RSVP.Promise} a promise resolving into a collection
       *  of systems retrieved from the backend
       * @tested
       */
      searchSystem: function searchSystem(systemName) {
        if (isBlank(systemName) || typeOf(systemName) !== 'string' || systemName.length < 3) {
          return [];
        }

        return get(this, 'store').query('system', {
          filter: {
            name: systemName
          }
        }).catch(function (error) {
          console.error(error);
        });
      }
    },

    /**
     * Sets 'password' property on model (!) if the 'password' property of the component(!)
     * is correct/valid and a password modification is required by the update (checkbox).
     * The raw password will be trimmed, hashed by sha512 algorithm and set.
     * If the password is not valid, or if its modification is not demanded, the user's model
     * 'password' property will be explicitly set to null so that the user serializer removes
     * it from the outgoing JSON message.
     * @see /serializers/user.js
     * @tested
     */
    setUserPasswordUponModel: function setUserPasswordUponModel() {
      // trim and hash password if it is valid, else skip it
      if (get(this, 'isPasswordChangeRequired') && !get(this, 'isPasswordInvalid')) {
        set(this, 'user.password', this.generateSHA512(get(this, 'password').trim()));
      } else {
        set(this, 'user.password', null); // set to null to make serializer omit it in outgoing JSON
      }
    }
  });
});