define('xamoom-admin/controllers/users/new', ['exports', 'xamoom-admin/mixins/processing-mixin', 'xamoom-admin/mixins/error-action-on-query-failure-sender-mixin'], function (exports, _processingMixin, _errorActionOnQueryFailureSenderMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  exports.default = Controller.extend(_processingMixin.default, _errorActionOnQueryFailureSenderMixin.default, {

    actions: {
      /**
       * Takes the new user instance, saves it on backend
       * and redirects user to the respective edit page, passing
       * the freshly created model as an argument.
       * This entire process is guarded by a progress bar indicator
       * that pops up at the beginning of the process and disappears
       * after the process is finished (successfully or unsuccessfully).
       * @tested
       */
      createUser: function createUser() {
        var _this = this;

        this.indicateProcessStart();

        get(this, 'model').save().then(function (user) {
          _this.transitionToRoute('users.edit', user);
        }).catch(function (error) {
          _this.send('error', error);
        }).finally(function () {
          _this.indicateProcessCompletion();
        });
      }
    }
  });
});