define('xamoom-admin/routes/systems/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'xamoom-admin/mixins/model-preloader-mixin'], function (exports, _authenticatedRouteMixin, _modelPreloaderMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var set = Ember.set;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _modelPreloaderMixin.default, {

    /**
     * Model-preloader-related specification determining models
     * (countries, system-statuses, languages) required by the route and controller
     * (systems/edit) whose properties should be set after models are loaded.
     * @tested
     */
    preloadConfig: {
      controllerName: 'systems/edit',
      preloadedModels: [{
        modelName: 'country',
        mappedTo: 'countries'
      }, {
        modelName: 'systemStatus',
        mappedTo: 'systemStatuses'
      }, {
        modelName: 'language',
        mappedTo: 'languages'
      }, {
        modelName: 'systemType',
        mappedTo: 'systemTypes'
      }]
    },

    /**
     * Delegates to AuthenticatedRouteMixin.beforeModel() to check user authentication
     * status and calls ModelPreloaderMixin.loadModelsAndSetControllerProperties()
     * to invoke setting (model) properties upon a controller.
     * @param {Transition} transition
     * @returns {Promise} result of the loadModelsAndSetControllerProperties() call
     * @tested
     */
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      return this.loadModelsAndSetControllerProperties();
    },


    /**
     * Returns a system instance looked up via parameter 'system_id'.
     * @returns {DS.Model}
     * @tested
     */
    model: function model(params) {
      return this.store.find('system', params.system_id);
    },


    actions: {
      /**
       * Sets an error property upon the application controller.
       * In case the model could not be found (code 500), we redirect to 'systems.index route'.
       * @param error
       * @returns {boolean} false to prevent action bubbling
       * Note: not tested
       */
      error: function error(_error) {
        // propagate
        set(this.controllerFor('application'), 'error', _error);

        try {
          // if model hasn't been found, redirect to systems.index
          if (_error.errors[0].code === 500) {
            this.transitionTo('systems.index');
          }
        } catch (e) {}
        // skip, no need to catch anything


        // do not propagate further
        return false;
      }
    }
  });
});