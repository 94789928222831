define('xamoom-admin/helpers/format-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDate = formatDate;
  var helper = Ember.Helper.helper;
  var isNone = Ember.isNone;
  var isPresent = Ember.isPresent;


  /* global moment */

  /**
   *
   * @param [String, ...] params - array with a datetime specified in ISO-8601 format on index 0
   * @param {{format: String}|*} namedArgs - object that can specify output format for the passed datetime
   * @returns {*}
   */
  function formatDate(params, namedArgs) {
    var outputFormat = 'DD.MM.YYYY HH:mm:ss';

    if (isNone(params) || isNone(params[0])) {
      console.error('format-date helper used passing no parameters!');
      return;
    }

    if (isPresent(namedArgs) && isPresent(namedArgs.format)) {
      outputFormat = namedArgs.format;
    }

    var isoDate = params[0];

    // eslint-disable-next-line
    var jsDateMoment = new moment(isoDate, moment.ISO_8601);

    if (!jsDateMoment.isValid()) {
      return 'INVALID-DATE-FORMAT';
    }

    return jsDateMoment.format(outputFormat);
  }

  exports.default = helper(formatDate);
});