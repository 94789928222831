define('xamoom-admin/routes/sessions/password/reset', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var setProperties = Ember.setProperties;
  var Route = Ember.Route;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, {

    /**
     * Resets controller properties to their default values.
     * @param {Ember.Controller} controller
     * @tested
     */
    setupController: function setupController(controller) {
      setProperties(controller, {
        isProcessing: false,
        email: null,
        passwordResetSucceeded: false,
        errorMessage: null
      });
    }
  });
});