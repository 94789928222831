define('xamoom-admin/components/system-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var run = Ember.run;
  var isNone = Ember.isNone;
  var isPresent = Ember.isPresent;
  var isBlank = Ember.isBlank;
  var typeOf = Ember.typeOf;
  var observer = Ember.observer;
  var setProperties = Ember.setProperties;
  var set = Ember.set;
  var get = Ember.get;
  var not = Ember.computed.not;
  var notEmpty = Ember.computed.notEmpty;
  var or = Ember.computed.or;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({

    // services
    /**
     * Date-helper service
     * @tested
     */
    dateHelper: service('date-helper'),

    // services
    /**
     * Store service
     */
    store: service(),

    // services
    /**
     * cloneSystem service
     */
    cloneSystem: service('clone-system'),

    // plain attributes
    /**
     * Flag that indicates correctness of the expiration date
     * {Boolean}
     * @tested
     */
    isExpiresOnCorrect: true,

    /**
     * Representation of the system's expiration time (UTC) in a local format
     * {String}
     * @tested
     */
    localDemoExpiresOn: '',

    // computed properties
    /**
     * Indicates incorrectness of the form.
     * By now, the form is considered incorrect if the isExpiresOnCorrect property is false
     * {Boolean}
     * @tested
     */
    isFormIncorrect: not('isExpiresOnCorrect'),

    /**
     * Indicates whether to show or to hide the system's location.
     * If the system model's property 'location' is empty,
     * it is desired not to show the system's location.
     * {Boolean}
     * @tested
     */
    isLocationShown: notEmpty('system.location'),

    /**
     * Indicates ability of the submit button.
     * The submit button is enabled (clickable) only in a case:
     * - form is correct AND
     * - no other process is being run.
     * In other words, an incorrect form OR a process already running will disable the submit button.
     * {Boolean}
     * @tested
     */
    isSubmitButtonDisabled: or('isFormIncorrect', 'isProcessing'), // isProcessing passed as an argument from controller

    // observers
    /**
     * Observes localDemoExpiresOn property.
     * Generates a string that represents the localDemoExpiresOn date in UTC format.
     * If valid, updates the system model which expects this UTC format
     * and sets correctness indicator to true.
     * If invalid, sets correctness indicator to false
     * and skips updating the system expiry date property.
     * @tested
     */
    updateUTCExpiryDateOnLocalExpiryDateChange: observer('localDemoExpiresOn', function () {
      var momentObjectUTC = void 0;

      // read local datetime entered in a format "DD.MM.YYYY HH:mm:ss"
      var enteredLocalDate = get(this, 'localDemoExpiresOn');
      if (isNone(enteredLocalDate)) {
        return;
      }

      try {
        momentObjectUTC = get(this, 'dateHelper').convertLocalToUTC(enteredLocalDate, 'DD.MM.YYYY HH:mm:ss');
      } catch (e) {
        console.error(e);
        setProperties(this, {
          isExpiresOnCorrect: false
        });
        return;
      }

      setProperties(this, {
        isExpiresOnCorrect: true,
        'system.demoExpiresOn': momentObjectUTC // ,
      });
    }),

    /**
     * Observes isLocationShown component property and updates system.location accordingly:
     * - if the isLocationShown is set to true,
     * system.location should point to the xamoom labs coord (if previous value
     * is not known), otherwise it becomes same value as it was before.
     * - otherwise, if the isLocationShown is set to false, system.location should be set to null
     * @tested
     */
    resetLocationOnDemand: observer('isLocationShown', function () {
      var showLocation = get(this, 'isLocationShown');

      if (isNone(showLocation)) {
        return;
      }

      if (!showLocation) {
        set(this, 'system.location', null);
        return;
      }

      if (isNone(get(this, 'system.location'))) {
        set(this, 'system.location', { lat: 46.6150313, lon: 14.261887 });
      }

      // else location is same as it was previously
    }),

    /**
     * If admin user sets system to a demo system, the default expiry date is present on the screen.
     * Otherwise, if user sets system to a non-demo system, the local expiry date resets to null,
     * system's expiry date is set to null and is extended flag is set to false, too.
     * @tested
     */
    clearFieldForDemoOnly: observer('system.isDemo', function () {
      var self = this;
      var isDemo = get(this, 'system.isDemo');

      if (isPresent(isDemo)) {
        if (isDemo) {
          run(function () {
            // set (local expiry date, it will trigger other observers to take care of date validity
            set(self, 'localDemoExpiresOn', '01.01.2018 08:00:00');
          });
        } else {
          // set dates to null and isExtendedDemo flag to false
          run(function () {
            set(self, 'localDemoExpiresOn', null);
            set(self, 'system.demoExpiresOn', null);
            set(self, 'system.isExtendedDemo', false);
          });
        }
      }
    }),

    /**
     * Checks if system.demoExpiresOn attribute is present.
     * If yes, initializeLocalExpiresOnProperty() is called.
     * @tested
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (isPresent(get(this, 'system.demoExpiresOn'))) {
        this.initializeLocalExpiresOnProperty();
      }
    },


    actions: {
      /**
       * Sends action "confirmChanges".
       * @tested
       */
      submit: function submit() {
        this.sendAction('confirmChanges');
      },


      /**
       * Returns a promise consumed by ember-power-select offering
       * a collection of systems. A selected system will be used in a user query.
       * Querying for users that belong to the system.
       * In a case of:
       *  - incorrect arguments
       *  - too short (<3) string
       * returns an empty array.
       * @param {string} systemName string used to perform a lookup by 'system name'
       * @returns {Array|Promise} a promise resolving into a collection of systems retrieved from the backend
       */
      searchSystem: function searchSystem(systemName) {
        if (isBlank(systemName) || typeOf(systemName) !== 'string' || systemName.length < 3) {
          set(this, 'cloneSystem.systemId', null);
          return [];
        }

        return get(this, 'store').query('system', {
          filter: {
            name: systemName
          }
        }).catch(function (error) {
          console.error(error);
        });
      },

      /**
       * sets the systemid in the service and selectedSystem
       * @param {*} system
       */
      setCloneSystemId: function setCloneSystemId(system) {
        var systemId = system ? get(system, 'id') : null;
        set(this, 'cloneSystem.systemId', systemId);
        set(this, 'selectedSystem', system);
      }
    },

    /**
     * Sets a value of the localDemoExpiresOn attribute to a new value:
     * local datetime representation of the UTC datetime retrieved
     * from system's demoExpiresOn property.
     * Swallows an error if occurs.
     * @tested
     */
    initializeLocalExpiresOnProperty: function initializeLocalExpiresOnProperty() {
      try {
        var utcExpiresOn = get(this, 'system.demoExpiresOn');
        set(this, 'localDemoExpiresOn', get(this, 'dateHelper').convertUTCToLocal(utcExpiresOn, 'DD.MM.YYYY HH:mm:ss'));
      } catch (e) {
        // never mind, log and continue
        console.error(e);
      }
    }
  });
});