define('xamoom-admin/controllers/users/index', ['exports', 'xamoom-admin/mixins/scrollable-controller-mixin'], function (exports, _scrollableControllerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var isPresent = Ember.isPresent;
  var isBlank = Ember.isBlank;
  var typeOf = Ember.typeOf;
  var Controller = Ember.Controller;
  var get = Ember.get;
  exports.default = Controller.extend(_scrollableControllerMixin.default, {
    /**
     * The model scrollable controller mixin will query for.
     * @tested
     */
    modelName: 'user',

    // search-bar-related values
    /**
     * Text used for full-text search.
     * {string}
     */
    searchQuery: null,
    /**
     * Selected user-role to build a query.
     * Users will be filtered by this user-role.
     * {DS.Model}
     * @tested
     */
    selectedRole: null,
    /**
     * Selected user-status to build a query.
     * Users will be filtered by this user-status.
     * {DS.Model}
     * @tested
     */
    selectedUserStatus: null,
    /**
     * Selected system to build a query.
     * Users will be filtered by this system they belong to.
     * {DS.Model}
     * @tested
     */
    selectedUserSystem: null,

    actions: {
      /**
       * Returns a promise consumed by ember-power-select offering
       * a collection of systems. A selected system will be used in a user query.
       * Querying for users that belong to the system.
       * In a case of:
       *  - incorrect arguments
       *  - too short (<3) string
       * returns an empty array.
       * @param {string} systemName string used to perform a lookup by 'system name'
       * @returns {Array|Promise} a promise resolving into a collection of systems retrieved from the backend
       * @tested
       */
      searchSystem: function searchSystem(systemName) {
        if (isBlank(systemName) || typeOf(systemName) !== 'string' || systemName.length < 3) {
          return [];
        }

        return this.store.query('system', {
          filter: {
            name: systemName
          }
        }).catch(function (error) {
          console.error(error);
        });
      }
    },

    /**
     * Attaches new attributes to the query object.
     * These attributes are attached because the users-index
     * resource needs them. The attributes are:
     *  - text (user nickname, firstname, lastname, email,... it's a fulltext search, so basically, everything text-based)
     *  - system (system the user belongs to)
     *  - role (current user role)
     *  - status (current user status)
     * @example
     *  let actualQuery = { what: "ever" };
     *  set(this, "searchQuery", "ema");
     *  set(this, "selectedUserSystem", this.store.peekRecord("system", 1));
     *  set(this, "selectedRole", this.store.peekRecord("userRole", 2));
     *  set(this, "selectedUserStatus", this.store.peekRecord("userStatus", 3));
     *  this.beforeQuery(actualQuery);
     *  console.log(actualQuery); // prints out:
     *  /*
     *  {
     *    what: "ever", // untouched
     *    filter: {
     *      fulltext: "ema",
     *      system: 1,
     *      role: 2,
     *      status: 3
     *    }
     *  }
     *  /*
     * @param {object} queryParam basic object composed by the mixin
     * @tested
     */
    beforeQuery: function beforeQuery(queryParam) {
      var query = queryParam;

      if (isNone(query)) {
        return;
      }

      var searchQuery = get(this, 'searchQuery');
      var system = get(this, 'selectedUserSystem');
      var role = get(this, 'selectedRole');
      var status = get(this, 'selectedUserStatus');

      if (isPresent(searchQuery) && searchQuery.trim().length > 0) {
        query.filter = query.filter || {};

        query.filter.fulltext = searchQuery.trim();
      }

      if (isPresent(system) && isPresent(get(system, 'id'))) {
        query.filter = query.filter || {};

        query.filter.system = get(system, 'id');
      }

      if (isPresent(role) && isPresent(get(role, 'id'))) {
        query.filter = query.filter || {};

        query.filter.role = get(role, 'id');
      }

      if (isPresent(status) && isPresent(get(status, 'id'))) {
        query.filter = query.filter || {};

        query.filter.status = get(status, 'id');
      }
    }
  });
});