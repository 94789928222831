define('xamoom-admin/routes/profile', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'xamoom-admin/mixins/model-preloader-mixin'], function (exports, _authenticatedRouteMixin, _modelPreloaderMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _modelPreloaderMixin.default, {

    /**
     * Session service
     * @tested
     */
    session: service('session'),

    /**
     * Model-preloader-related specification determining models
     * (user-roles, user-statuses)required by the route and controller
     * (profile) whose properties should be set after models are loaded.
     * @tested
     */
    preloadConfig: {
      controllerName: 'profile',
      preloadedModels: [{
        modelName: 'userRole',
        mappedTo: 'userRoles'
      }, {
        modelName: 'userStatus',
        mappedTo: 'userStatuses'
      }]
    },

    /**
     * Delegates to AuthenticatedRouteMixin.beforeModel() to check user authentication
     * status and calls ModelPreloaderMixin.loadModelsAndSetControllerProperties()
     * to invoke setting (model) properties upon a controller.
     * @param {Transition} transition
     * @returns {Promise} result of the loadModelsAndSetControllerProperties() call
     */
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      return this.loadModelsAndSetControllerProperties();
    },


    /**
     * Returns a promise (user model) looked up in the store via a user ID stored
     * in the session data. The session data is handled by the session service
     * and we expect the data to be there for any authenticated user.
     * @returns {Promise} user model representing currently logged in user
       */
    model: function model() {
      var userId = get(this, 'session.data.authenticated.data.id');

      return this.store.find('user', userId);
    }
  });
});