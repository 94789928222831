define('xamoom-admin/components/alerts-handler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var set = Ember.set;
  exports.default = Component.extend({

    /**
     * Removes the .callout section by fading out.
     * Afterwards, sets the error property to null
     * which causes the entire .callout wrapper to disappear.
     */
    click: function click() {
      var _this = this;

      var el = this.$('.callout');
      el.fadeOut(800, function () {
        set(_this, 'error', null);
      });
    }
  });
});