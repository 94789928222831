define('xamoom-admin/authenticators/xamoom-cloud-authenticator', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'xamoom-admin/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  var $ = Ember.$;
  var get = Ember.get;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  exports.default = _oauth2PasswordGrant.default.extend({

    /**
     * Development mode flag imported from configuration.
     * @property isDevMode
     * @type String
     * @tested
     */
    isDevMode: _environment.default.APP.DEV_MODE,

    /**
     * Development key value imported from configuration.
     * @property devKey
     * @type String
     * @tested
     */
    xDevKey: _environment.default.APP.X_DEV_KEY,

    /**
     * xamoom authentication endpoint
     * @property serverTokenEndpoint
     * @type String
     * @tested
     */
    serverTokenEndpoint: _environment.default.APP.BASE_URL + '/' + _environment.default.APP.AUTH_NAMESPACE + '/login',

    /**
     * Serves request headers as an object which looks either like this:
     * @example
     * // (development environment)
     * {
     *  'Content-Type': 'application/vnd.api+json',
     *  'X-Devkey': config.APP.X_DEV_KEY
     * }
     * // (production environment)
     * {
     *  'Content-Type': 'application/vnd.api+json'
     * }
     * @property headers
     * @type Computed<Object>
     * @overrides
     * @tested
     */
    headers: computed('isDevMode', 'xDevKey', function () {
      var headers = {
        'Content-Type': 'application/vnd.api+json'
      };

      if (get(this, 'isDevMode') && isPresent(get(this, 'xDevKey'))) {
        headers['X-Devkey'] = get(this, 'xDevKey');
      }

      return headers;
    }),

    /**
     * Makes a request to the OAuth 2.0 server.
     * @override
     * @param {String} url The request URL
     * @param {Object} data The request data
     * @returns {jQuery.Deferred} A promise like jQuery.Deferred as returned by `$.ajax`
     * @tested
     */
    makeRequest: function makeRequest(url, data) {
      var options = {
        url: url,
        data: data,
        type: 'GET',
        dataType: 'json',
        accept: 'application/vnd.api+json',
        headers: get(this, 'headers')
      };

      return $.ajax(options);
    },


    /**
     * Contains logic for restoring a session-token from the received data object
     * @method restore
     * @override
     * @param {Object} payload received
     * @returns {Ember.RSVP.Promise}
     * @tested
     */
    restore: function restore(payload) {
      return new EmberPromise(function (resolve, reject) {
        if (isEmpty(get(payload, 'meta.session-token'))) {
          reject();
        } else {
          resolve(payload);
        }
      });
    },


    /**
     * Validates server's authentication response
     * @method _validate
     * @param {Object} payload received
     * @returns {Boolean} true if valid, otherwise false
     * @override
     * @private
     * @tested
     */
    _validate: function _validate(payload) {
      return isPresent(payload.data) && isPresent(payload.meta);
    }
  });
});