define('xamoom-admin/services/date-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Service.extend({

    /** *
     * An ember service wrapping moment.js library
     * @tested
     */
    moment: service(),

    /**
     * Converts a local date of the format "inputFormat" to its UTC form.
     * If no input format is passed, format "DD.MM.YYYY HH:mm:ss" is expected.
     * @example
     *  convertLocalToUTC("01.01.2019 00:59:59") returns "2018-12-31T23:59:59+00:00"
     *
     * @param {String} localDateAsString local date as string
     * @param {String} inputFormat format used to read the input local date
     * @throws {Error} Error if the input date is invalid
     * @returns {String} a date converted into a UTC format
       */
    convertLocalToUTC: function convertLocalToUTC(localDateAsString) {
      var inputFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD.MM.YYYY HH:mm:ss';

      var momentService = get(this, 'moment');

      // parse local date
      var momentObject = momentService.moment(localDateAsString, inputFormat);

      if (!momentObject.isValid()) {
        throw new Error('Date not valid!');
      }

      // switch to UTC mode and return its string representation (ISO_8604 by default)
      return momentObject.utc().format();
    },


    /**
     * Converts a date that comes in in a UTC format into its local representation.
     * If no output format is specified, the format "DD.MM.YYYY HH:mm:ss" will be applied.
     * @example
     *  convertUTCToLocal("2018-12-31T23:59:59+00:00") returns "01.01.2019 00:59:59"
     * @param {String} utcDateString UTC date to be converted
     * @param {String} outputFormat format used to generate the local date
     * @returns {String} a date converted into a local date
       */
    convertUTCToLocal: function convertUTCToLocal(utcDateString) {
      var outputFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD.MM.YYYY HH:mm:ss';

      var moment = get(this, 'moment');

      // initialize moment object using the UTC date
      var momentObject = moment.moment(utcDateString);

      // check validity, throw exception if not valid
      if (!momentObject.isValid()) {
        throw new Error('Date not valid!');
      }

      // convert to local and format
      return momentObject.local().format(outputFormat);
    }
  });
});