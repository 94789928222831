define('xamoom-admin/serializers/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  exports.default = _emberData.default.JSONAPISerializer.extend({

    /**
     * Overrides serializeAttribute() in order to omit inclusion empty (undefined, null) passwords.
     * Outgoing JSON must not have a password attribute being empty because of the backend logic which
     * expects that whenever the password is present in a JSON, it has to be used (set). Passing an empty
     * one would cause an error - backend would complain about empty password. The backend expects
     * no 'password' attribute at all as long as it is not manipulated.
     * @param {DS.Snapshot} snapshotParam
     * @param {Object} json
     * @param {String} key
     * @param {Object} attribute
     * @override DS.JSONAPISerializer.serializeAttribute
     */
    serializeAttribute: function serializeAttribute(snapshotParam, json, key, attribute) {
      var snapshot = snapshotParam;

      // if password is not set, delete it from snapshot and do NOT add it to the JSON.
      if (key === 'password' && isEmpty(snapshot.attr('password'))) {
        delete snapshot.attributes.password;
      } else {
        // let the parent method take over otherwise
        this._super(snapshot, json, key, attribute);
      }
    }
  });
});