define('xamoom-admin/routes/sessions/logout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({

    /**
     * Retrieves the sessions.logout controller and sends a logout action to it
     * @tested
     */
    beforeModel: function beforeModel() {
      this.controllerFor('sessions.logout').send('logout');
    }
  });
});