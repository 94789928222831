define('xamoom-admin/services/csv-file-generator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var typeOf = Ember.typeOf;
  var Service = Ember.Service;
  exports.default = Service.extend({

    /**
     * Generates header according to arguments passed.
     * @example
     * generateHeader("a", "b", "c") would return [["a", "b", "c"]]
     * @param {...string} args any amount of strings
     * @returns {[[string]]} array of array of strings
     * @throws {Error} if no arguments are present
     * @tested
     */
    generateHeader: function generateHeader() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      if (isNone(args) || args.length === 0) {
        throw new Error('No arguments provided. It is not possible to create CSV header for 0 arguments');
      }

      return [Array.prototype.slice.call(args)];
    },


    /**
     * Updates data by appending a new row to the current data.
     * @example
     *  let actualData = [['a', 'b', 'c']];
     *  appendRow( actualData, 'e', 'f', 'g' );
     *  console.log( actualData ); // [['a', 'b', 'c'], ['e', 'f', 'g']]
     * @param {[[string]]} actualData in a form of an array of arrays of strings
     * @param {...string} rowToAppend as a consequence of string arguments
     * @throws {Error} on no arguments
     * @throws {Error} on first argument not being instance of array
     * @throws {Error} on no more than 1 argument / on 1 argument only
     * @tested
       */
    appendRow: function appendRow(actualData) {
      if (isNone(actualData)) {
        throw new Error('No arguments provided. It is not possible to append a row to undefined');
      }

      if (typeOf(actualData) !== 'array') {
        throw new Error('First argument passed to appendRow() must be array!');
      }

      for (var _len2 = arguments.length, rowToAppend = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        rowToAppend[_key2 - 1] = arguments[_key2];
      }

      if (isNone(rowToAppend) || rowToAppend.length === 0) {
        throw new Error('Second+ argument not provided. It is not possible to append a row which is unspecified or an empty array');
      }

      actualData.push(Array.prototype.slice.call(rowToAppend));
    },


    /**
     * Creates an anchor element, sets its href property to a base64 data generated
     * from the data argument passed. Anchor's download attribute represents
     * its file name.
     * @param {string} fileName - CSV file name
     * @param {[[string]]} data - CSV dats
     * @returns {Element} anchor <a> element representing CSV file
     * @private
     * @tested
     */
    _createAnchor: function _createAnchor() {
      var fileName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'data';
      var data = arguments[1];

      var csv = [];
      var anchor = document.createElement('a');

      for (var i = 0, len = data.length; i < len; i += 1) {
        csv.push(data[i].join(';')); // cell separator
      }

      anchor.href = 'data:attachment/csv,' + encodeURIComponent(csv.join('\n'));

      anchor.target = '_blank';

      anchor.download = fileName + '.csv';

      return anchor;
    },


    /**
     * Calls click() on an (anchor) element passed to it in a form of argument.
     * @param {HTMLAnchorElement} anchor
     * @private
     * @tested
     */
    _clickAnchor: function _clickAnchor(anchor) {
      document.body.appendChild(anchor);

      anchor.click();
    },


    /**
     * Creates an anchor element and triggers a click action upon it.
     * @param {string} fileName of the CSV file produced at the end
     * @param {[[string]]} data representing CSV data
     * @throws {Error} on no arguments
     * @throws {Error} on first argument not being a string
     * @throws {Error} on no second argument / second argument missing
     * @throws {Error} on second argument not being an array
     * @tested
     */
    createAndDownloadCSV: function createAndDownloadCSV(fileName, data) {
      if (isNone(fileName)) {
        throw new Error('No arguments provided. It is not possible to append create CSV with no filename and data');
      }

      if (typeOf(fileName) !== 'string') {
        throw new Error('Argument provided as a CSV filename is not a string.');
      }

      if (isNone(data)) {
        throw new Error('No data to be used for CSV generation. Pass also the second argument please.');
      }

      if (typeOf(data) !== 'array') {
        throw new Error('Argument provided as a CSV data is not an array.');
      }

      // create <a>
      var anchor = this._createAnchor(fileName, data);

      // force download
      this._clickAnchor(anchor);
    }
  });
});