define('xamoom-admin/models/system-status', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = _model.default.extend({
    /**
     * System-status name.
     * @property name
     * @type String
     * @public
     * @tested
     */
    name: (0, _attr.default)('string'),

    /**
     * Determines whether system-status is a status: active.
     * @property isActive
     * @type Boolean true if system-status ID is 0, otherwise false.
     * @public
     * @tested
     */
    isActive: computed('id', function () {
      var systemStatusId = get(this, 'id');

      if (isNone(systemStatusId)) {
        return false;
      }

      return parseInt(systemStatusId, 10) === 0;
    }),

    /**
     * Determines whether system-status is a status: deactivated.
     * @property isDeactivated
     * @type Boolean true if system-status ID is 1, otherwise false.
     * @public
     * @tested
     */
    isDeactivated: computed('id', function () {
      var systemStatusId = get(this, 'id');

      if (isNone(systemStatusId)) {
        return false;
      }

      return parseInt(systemStatusId, 10) === 1;
    }),

    /**
     * Determines whether system-status is a status: deleted.
     * @property isDeleted
     * @type Boolean true if system-status ID is 2, otherwise false.
     * @public
     * @tested
     */
    isDeleted: computed('id', function () {
      var systemStatusId = get(this, 'id');

      if (isNone(systemStatusId)) {
        return false;
      }

      return parseInt(systemStatusId, 10) === 2;
    })

  });
});