define('xamoom-admin/authorizers/xamoom-cloud-authorizer', ['exports', 'ember-simple-auth/authorizers/base', 'xamoom-admin/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var isPresent = Ember.isPresent;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  exports.default = _base.default.extend({

    /**
     * Development mode flag imported from configuration.
     * @property isDevModepfix
     * @type String
     * @tested
     */
    isDevMode: _environment.default.APP.DEV_MODE,

    /**
     * Development key value imported from configuration.
     * @property xDevKey
     * @type String
     * @tested
     */
    xDevKey: _environment.default.APP.X_DEV_KEY,

    /**
     * Session service
     * @property session
     * @type Service
     * @tested
     */
    session: service(),

    /**
     * Authorizes all outgoing requests with a session-token
     * that has been received during a login process.
     * If such a session token does not exist, it does not add anything.
     * @method authorize
     * @param {Object} sessionData received from the backend on a successful login
     * @param {Function} addHeaderFunction function that appends a custom header
     *      into the next AJAX call
     * @override
     * @tested
     */
    authorize: function authorize(sessionData, addHeaderFunction) {
      var sessionToken = get(sessionData, 'meta.session-token');
      if (!isEmpty(get(this, 'session.data.token'))) {
        sessionToken = get(this, 'session.data.token');
      }
      if (isPresent(sessionToken)) {
        addHeaderFunction('authorization', sessionToken);
      }

      // include the dev key if development mode is on
      if (get(this, 'isDevMode') && isPresent(get(this, 'xDevKey'))) {
        addHeaderFunction('X-Devkey', get(this, 'xDevKey'));
      }
    }
  });
});