define('xamoom-admin/controllers/profile', ['exports', 'xamoom-admin/mixins/processing-mixin'], function (exports, _processingMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  exports.default = Controller.extend(_processingMixin.default, {

    actions: {
      /**
       * Method that delegates update logic
       * to the updateUser() class method.
       * @tested
       */
      updateUser: function updateUser() {
        this.updateUser();
      }
    },

    /**
     * Method that updates the (current) user (profile).
     * Initializes progress bar, performs an update
     * and terminates the progress bar either on success
     * or failure.
     * @tested
     */
    updateUser: function updateUser() {
      var _this = this;

      this.indicateProcessStart();

      get(this, 'model').save().catch(function (error) {
        return _this.send('error', error);
      }).finally(function () {
        return _this.indicateProcessCompletion();
      });
    }
  });
});