define('xamoom-admin/models/system', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    /**
     * API key.
     */
    apiKey: _emberData.default.attr('string'),

    /**
     * Date of system creation.
     */
    createdAt: _emberData.default.attr('string'),

    /**
     * Flag determining whether the system has a push notifications (integration) enabled.
     */
    isPushEnabled: _emberData.default.attr('boolean', { defaultValue: false }),

    /**
     * Flag determining whether the system has hosted mwp
     */
    isHostedMWP: _emberData.default.attr('boolean', { defaultValue: false }),

    isPremiumStatisticsEnabled: _emberData.default.attr('boolean'),

    isChatbotEnabled: _emberData.default.attr('boolean'),

    isVouchersEnabled: _emberData.default.attr('boolean'),

    isAutoTranslateEnabled: _emberData.default.attr('boolean'),

    isFrontendConfigEnabled: _emberData.default.attr('boolean'),

    isLanguageSwitcherEnabled: _emberData.default.attr('boolean'),

    isFormsEnabled: _emberData.default.attr('boolean'),

    isDataCycleImportEnabled: _emberData.default.attr('boolean'),

    /**
     * Object, container holding latitude and longitude values.
     * @example
     * {
     *  lat: 43.56,
     *  lon: 12.112
     * }
     */
    location: _emberData.default.attr(), // eslint-disable-line

    /**
     * Amount of markers allowed to be bound to (produced for) the system.
     */
    maxMarkers: _emberData.default.attr('number'),

    /**
     * Amount of users allowed to be active withing the system.
     */
    maxMaus: _emberData.default.attr('number'),

    /**
     * Amount of pages allowed to be created within the system.
     */
    maxPages: _emberData.default.attr('number'),

    /**
     * Amount of spots allowed to be bound to the system.
     */
    maxSpots: _emberData.default.attr('number'),

    /**
     * Amount of media-items-related data allowed for the system.
     */
    maxStorage: _emberData.default.attr('number'),

    /**
     * Amount of data allowed to be transmitted for this particular system.
     */
    maxUsage: _emberData.default.attr('number'),

    /**
     * Amount of users allowed to be created within the system.
     */
    maxUsers: _emberData.default.attr('number'),

    /**
     * System name.
     */
    name: _emberData.default.attr('string'),

    /**
     * Relationship to the model: country.
     * A system belongs to / is associated with a country.
     * system -> 1 country
     */
    country: _emberData.default.belongsTo('country', {
      async: false
    }),

    /**
     * Relationship to the model: language.
     * A system belongs to / is associated with a language.
     * system -> 1 language
     */
    language: _emberData.default.belongsTo('language', {
      async: false
    }),

    /**
     * Relationship to the model: systemStatus.
     * A system is associated with a system-status.
     * A system has a status, 1 status at the time.
     * system -> 1 system-status
     */
    status: _emberData.default.belongsTo('systemStatus', {
      async: false
    }),

    /**
     * Relationship to the model: systemType.
     * A system is associated with a system-type.
     * A system has a type, 1 type at the time.
     * system -> 1 system-type
     */
    type: _emberData.default.belongsTo('systemType', {
      async: false
    })
  });
});