define('xamoom-admin/mixins/scrollable-controller-mixin', ['exports', 'xamoom-admin/mixins/error-action-on-query-failure-sender-mixin'], function (exports, _errorActionOnQueryFailureSenderMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var scheduleOnce = Ember.run.scheduleOnce;
  var copy = Ember.copy;
  var isPresent = Ember.isPresent;
  var isNone = Ember.isNone;
  var typeOf = Ember.typeOf;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;
  var alias = Ember.computed.alias;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create(_errorActionOnQueryFailureSenderMixin.default, {

    /**
     * Flag which determines any process is being carried out.
     * {boolean}
     * @tested
     */
    isProcessing: false,

    /**
     * Actual page size.
     * {number}
     * @tested
     */
    size: 10,

    /**
     * Page (batch) sizes user can choose from when loading the data.
     * {[Number]}
     * @tested
     */
    pageSizes: [10, 20, 30, 50, 100],

    /**
     * Aliases the property 'has-more' of the meta object that belongs to the model.
     * {boolean}
     * @tested
     */
    hasMore: alias('model.meta.has-more'),

    /**
     * Aliases the property 'cursor' of the meta object that belongs to the model.
     * {number}
     * @tested
     */
    cursor: alias('model.meta.cursor'),

    /**
     * Aliases the property 'total' of the meta object that belongs to the model.
     * {number}
     * @tested
     */
    totalModels: alias('model.meta.total'),

    /**
     * Represents a simple query object of the following structure:
     * @example
     * {
     *  page: {
     *    size: @size
     *  }
     * }.
     * This object is used when user triggers the 'filter' action.
     * No cursor information is necessary thus no dependency on
     * the 'cursor' property is required.
     * {{page: {size: {NUMBER}}}}
     * @tested
     */
    queryObjectWithoutCursor: computed('size', function () {
      var queryObject = {};

      var size = get(this, 'size');

      if (isPresent(size)) {
        queryObject.page = {
          size: size
        };
      }

      return queryObject;
    }),

    /**
     * Represents a simple query object of the following structure:
     * @example
     * {
     *  page: {
     *    size: @size,
     *    cursor: @cursor
     *  }
     * }.
     * This object is used when user triggers the 'loadMore' action.
     * It relies on computed property 'queryObjectWithoutCursor',
     * which gets enriched by a new attribute (called 'cursor').
     * {{page: {size: {NUMBER}, cursor: {NUMBER}}}}
     * @tested
     */
    queryObjectWithCursor: computed('cursor', 'queryObjectWithoutCursor', function () {
      var cursor = get(this, 'cursor');
      var queryObjectWithoutCursor = copy(get(this, 'queryObjectWithoutCursor'), true);

      if (isPresent(cursor)) {
        queryObjectWithoutCursor.page = queryObjectWithoutCursor.page || {};
        queryObjectWithoutCursor.page.cursor = cursor; // now it has cursor, too
      }

      return queryObjectWithoutCursor; // cursor included
    }),

    /**
     * Flag determining the ability state of the load-more button.
     * If true, the load-more button is disabled.
     * If false, the load-more button is enabled.
     * {boolean} the flag value
     * @tested
     */
    isLoadMoreButtonDisabled: computed('hasMore', 'isProcessing', function () {
      return get(this, 'hasMore') === false || get(this, 'isProcessing') === true;
    }),

    /**
     * Performs filtering in the following steps:
     * - checks if the 'modelName' property is present (and throws an error if it's missing),
     * - sets a flag determining a running process to true,
     * - retrieves a computed query object (it's filtering, no cursor is needed),
     * - calls beforeQuery() if exists,
     * - creates a promise to load either a single model instance or a collection of them according to the query.id presence
     * - if the promise resolves, it:
     *    - calls afterQuery()
     *    - sets the model
     * - if the promise rejects, it:
     *    - calls onQueryFailure(),
     * - sets a flag determining a running process to false,
     * @returns {*|Promise} Promise resolving into a single model instance or a collection of them
     * @throws {Error} if extending class forgets to determine its 'modelName' property
     * @tested
     */
    doFilter: function doFilter() {
      var _this = this;

      var promise = void 0;
      var modelName = get(this, 'modelName');

      if (isNone(modelName)) {
        throw new Error("Name of the model to load is not defined in the controller extending this mixin. Solve the problem by defining a property called 'modelName' within the controller.");
      }

      set(this, 'isProcessing', true);

      var query = copy(get(this, 'queryObjectWithoutCursor'), true);

      if (typeOf(this.beforeQuery) === 'function') {
        this.beforeQuery(query);
      }

      if (isPresent(query.id)) {
        promise = this.store.queryRecord(modelName, query);
      } else {
        promise = this.store.query(modelName, query);
      }

      return promise.then(function (loadedSystems) {
        var systems = loadedSystems;

        if (typeOf(_this.afterQuery) === 'function') {
          systems = _this.afterQuery(systems);
        }

        // set the model (getting rid of the previous model representation)
        scheduleOnce('afterRender', function () {
          set(_this, 'model', systems);
        });
      }).catch(function (error) {
        if (typeOf(_this.onQueryFailure) === 'function') {
          _this.onQueryFailure(error);
        }
      }).finally(function () {
        scheduleOnce('afterRender', function () {
          set(_this, 'isProcessing', false);
        });
      });
    },


    /**
     * Performs next-page-load in the following steps:
     * - checks if the 'modelName' property is present (and throws an error if it's missing),
     * - sets a flag determining a running process to true,
     * - retrieves a computed query object (cursor is needed),
     * - calls beforeQuery() if exists,
     * - creates a promise to load a collection of models
     * - if the promise resolves, it:
     *    - calls afterQuery()
     *    - retrieves current model instances and adds them to the beginning of the collection of currently fetched models,
     * - if the promise rejects, it:
     *    - calls onQueryFailure(),
     * - sets a flag determining a running process to false,
     * @returns {*|Promise} Promise resolving into a collection of model instances
     * @throws {Error} if extending class forgets to determine its 'modelName' property
     * @tested
     */
    doLoadNextPage: function doLoadNextPage() {
      var _this2 = this;

      var modelName = get(this, 'modelName');

      if (isNone(modelName)) {
        throw new Error("Name of the model to load is not defined in the controller extending this mixin. Solve the problem by defining a property called 'modelName' within the controller.");
      }

      set(this, 'isProcessing', true);

      var query = copy(get(this, 'queryObjectWithCursor'), true);

      if (typeOf(this.beforeQuery) === 'function') {
        this.beforeQuery(query);
      }

      return this.store.query(modelName, query).then(function (loadedSystems) {
        var systems = loadedSystems;

        if (typeOf(_this2.afterQuery) === 'function') {
          systems = _this2.afterQuery(systems);
        }
        // set the model (getting rid of the previous model representation)
        scheduleOnce('afterRender', function () {
          // get the model and add freshly loaded array
          var currentModels = get(_this2, 'model.content');
          systems.unshiftObjects(currentModels);
          set(_this2, 'model', systems);
        });
      }).catch(function (error) {
        if (typeOf(_this2.onQueryFailure) === 'function') {
          _this2.onQueryFailure(error);
        }
      }).finally(function () {
        scheduleOnce('afterRender', function () {
          set(_this2, 'isProcessing', false);
        });
      });
    },


    actions: {
      /**
       * Calls doFilter().
       * @tested
       */
      filter: function filter() {
        this.doFilter();
      },


      /**
       * Calls doLoadNextPage().
       * @tested
       */
      loadNextPage: function loadNextPage() {
        this.doLoadNextPage();
      }
    }
  });
});