define('xamoom-admin/controllers/markers/index', ['exports', 'xamoom-admin/mixins/scrollable-controller-mixin'], function (exports, _scrollableControllerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ArrayProxy = Ember.ArrayProxy;
  var isArray = Ember.isArray;
  var A = Ember.A;
  var isNone = Ember.isNone;
  var isPresent = Ember.isPresent;
  var isBlank = Ember.isBlank;
  var typeOf = Ember.typeOf;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  var get = Ember.get;
  exports.default = Controller.extend(_scrollableControllerMixin.default, {

    /**
     * CSV-file-generator service.
     * {Ember.InjectedProperty}
     * @tested
     */
    csv: service('csv-file-generator'),

    /**
     * The model scrollable controller mixin will query for.
     * @tested
     */
    modelName: 'marker',

    // search-bar-related values
    /**
     * Text used for full-text search.
     * {string}
     */
    searchQuery: null,

    /**
     * Selected system to build a query.
     * Markers will be filtered by this system they belong to.
     * {DS.Model}
     * @tested
     */
    selectedSystem: null,

    actions: {
      /**
       * Returns a promise consumed by ember-power-select offering
       * a collection of systems. A selected system will be used in a marker query.
       * Querying for markers that belong to the system.
       * In a case of:
       *  - incorrect arguments
       *  - too short (<3) string
       * returns an empty array.
       * @param {string} systemName string used to perform a lookup by 'system name'
       * @returns {Array|Promise} a promise resolving into a collection of systems retrieved from the backend
       * @tested
       */
      searchSystem: function searchSystem(systemName) {
        if (isBlank(systemName) || typeOf(systemName) !== 'string' || systemName.length < 3) {
          return [];
        }
        return this.store.query('system', {
          filter: {
            name: systemName
          }
        }).catch(function (error) {
          console.error(error);
        });
      },


      // TODO
      downloadCSV: function downloadCSV() {
        var fileName = 'markers';
        var csvService = get(this, 'csv');

        // prepare header
        var data = csvService.generateHeader('spot', 'nfc', 'qr', 'iBeacon Region UUID', 'iBeacon Major', 'iBeacon Minor', 'Eddystone', 'Produced?');

        // append every single row
        get(this, 'model').forEach(function (marker) {
          csvService.appendRow(data, get(marker, 'spot.name'), get(marker, 'nfc'), get(marker, 'qr'), get(marker, 'ibeaconRegionUid'), get(marker, 'ibeaconMajor'), get(marker, 'ibeaconMinor'), get(marker, 'eddystoneUrl'), get(marker, 'isProduced') ? 'yes' : 'no');
        });

        csvService.createAndDownloadCSV(fileName, data);
      }
    },

    /**
     * Returns data received as an argument in a form of array. The respective template expects
     * an array. Thus, in case a single marker (retrieved by queryRecord()) is returned,
     * the afterQuery() method wraps it into an ArrayProxy - an array with a single item.
     * @param {DS.RecordArray|DS.} loadedMarkers
     * @returns {DS.RecordArray|DS.Model}
     * @tested
     */
    afterQuery: function afterQuery(loadedMarkers) {
      if (isArray(loadedMarkers)) {
        return loadedMarkers;
      } else {
        return ArrayProxy.create({
          content: A([loadedMarkers]),
          meta: {
            cursor: null,
            'has-more': false,
            total: 1
          }
        });
      }
    },


    /**
     * Attaches new attributes to the query object.
     * These attributes are attached because the markers-index
     * resource needs them. The attributes are:
     *  - text (no full-text search, this text represents marker ID)
     *  - system (system the marker(s) belongs to)
     * @example 1
     *  let actualQuery = { what: "ever" };
     *  set(this, "searchQuery", "0ana0");
     *  set(this, "selectedSystem", null);
     *  this.beforeQuery(actualQuery);
     *  console.log(actualQuery); // prints out:
     *  /*
     *  {
     *    what: "ever", // untouched
     *    id: "0ana0"
     *  }
     *  /*
     * @example 2
     *  let actualQuery = { what: "ever" };
     *  set(this, "searchQuery", null);
     *  set(this, "selectedSystem", this.store.peekRecord("system", 100));
     *  this.beforeQuery(actualQuery);
     *  console.log(actualQuery); // prints out:
     *  /*
     *  {
     *    what: "ever", // untouched
     *    filter: {
     *      system: 100
     *    }
     *  }
     *  /*
     *
     *  Note: beforeQuery() explicitly deletes ID property set upon query object during
     *  previous marker look-ups. This needs to be done because of different calls:
     *  - once no search text (no marker ID) is present, it's a query() call,
     *  - once search text (marker ID) is present, it's a queryRecord() call.
     *  queryRecord() needs ID which is not a standard case and thus we need to explicitly
     *  tidy up the leftover (the query.id property).
     *
     * @param {object} queryParam basic object composed by the mixin
     * @tested
     */
    beforeQuery: function beforeQuery(queryParam) {
      var query = queryParam;

      if (isNone(query)) {
        return;
      }

      var searchQuery = get(this, 'searchQuery');
      var system = get(this, 'selectedSystem');

      // delete ID property explicitly because it is still present from previous call, although searchQuery doesn't have to be defined now
      if (isPresent(query.id)) {
        delete query.id;
      }

      if (isPresent(searchQuery) && searchQuery.trim().length > 0) {
        query.id = searchQuery.trim();
      }

      if (isPresent(system) && isPresent(get(system, 'id'))) {
        query.filter = query.filter || {};
        query.filter.system = get(system, 'id');
      }
    }
  });
});