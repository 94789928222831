define('xamoom-admin/routes/systems/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'xamoom-admin/mixins/model-preloader-mixin'], function (exports, _authenticatedRouteMixin, _modelPreloaderMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _modelPreloaderMixin.default, {

    /**
     * Model-preloader-related specification determining models
     * (countries, system-statuses, languages) required by the route and controller
     * (systems/new) whose properties should be set after models are loaded.
     * @tested
     */
    preloadConfig: {
      controllerName: 'systems/new',
      preloadedModels: [{
        modelName: 'country',
        mappedTo: 'countries'
      }, {
        modelName: 'systemStatus',
        mappedTo: 'systemStatuses'
      }, {
        modelName: 'language',
        mappedTo: 'languages'
      }, {
        modelName: 'systemType',
        mappedTo: 'systemTypes'
      }]
    },

    /**
     * Delegates to AuthenticatedRouteMixin.beforeModel() to check user authentication
     * status and calls ModelPreloaderMixin.loadModelsAndSetControllerProperties()
     * to invoke setting (model) properties upon a controller.
     * @param {Transition} transition
     * @returns {Promise} result of the loadModelsAndSetControllerProperties() call
     * @tested
     */
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      return this.loadModelsAndSetControllerProperties();
    },


    /**
     * Returns a freshly created system record.
     * @returns {DS.Model}
     * @tested
     */
    model: function model() {
      return this.store.createRecord('system');
    }
  });
});