define('xamoom-admin/controllers/systems/edit', ['exports', 'xamoom-admin/mixins/processing-mixin'], function (exports, _processingMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var get = Ember.get;
  exports.default = Controller.extend(_processingMixin.default, {

    actions: {
      /**
       * Method that delegates update logic
       * to the updateUser() class method.
       * @tested
       */
      updateSystem: function updateSystem() {
        if (get(this, 'model.status.isDeleted')) {
          this.deleteSystem();
        } else {
          this.updateSystem();
        }
      }
    },

    /**
     * Takes the underlying system instance and updates it on backend.
     * This entire process is guarded by a progress bar indicator
     * that pops up at the beginning of the process and disappears
     * after the process is finished (successfully or unsuccessfully).
     * @tested
     */
    updateSystem: function updateSystem() {
      var _this = this;

      this.indicateProcessStart();

      get(this, 'model').save().catch(function (error) {
        return _this.send('error', error);
      }).finally(function () {
        return _this.indicateProcessCompletion();
      });
    },


    /**
     * Takes the underlying system instance and sends a delete
     * afterwards it transitions to /systems route
     * @tested
     */
    deleteSystem: function deleteSystem() {
      var _this2 = this;

      this.indicateProcessStart();

      get(this, 'model').destroyRecord().catch(function (error) {
        return _this2.send('error', error);
      }).finally(function () {
        _this2.indicateProcessCompletion();
        _this2.transitionToRoute('systems');
      });
    }
  });
});