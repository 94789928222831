define('xamoom-admin/mixins/error-action-on-query-failure-sender-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({

    /**
     * Sends an error action passing an error object representing problems that occur.
     * @param {Ember.Error} error
     * @tested
     */
    onQueryFailure: function onQueryFailure(error) {
      this.send('error', error);
    }
  });
});