define('xamoom-admin/controllers/systems/index', ['exports', 'xamoom-admin/mixins/scrollable-controller-mixin'], function (exports, _scrollableControllerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var isPresent = Ember.isPresent;
  var Controller = Ember.Controller;
  var get = Ember.get;
  exports.default = Controller.extend(_scrollableControllerMixin.default, {
    /**
     * The model scrollable controller mixin will query for.
     * @tested
     */
    modelName: 'system',

    // search-bar-related values
    /**
     * (Selected) System name used to build a query.
     * Systems will be filtered by this name.
     * @tested
     */
    name: null,

    /**
     * Selected country used to build a query.
     * Systems will be filtered by this country.
     * @ŧested
     */
    selectedCountry: null,

    /**
     * Selected system-status to build a query.
     * Systems will be filtered by this system-status.
     * @tested
     */
    selectedSystemStatus: null,

    /**
     * Selected system-type to build a query.
     * Systems will be filtered by this system-type.
     * @tested
     */
    selectedSystemType: null,

    /**
     * Attaches new attributes to the query object.
     * These attributes are attached because the system-index
     * resource needs them. The attributes are:
     *  - name (system name)
     *  - country (system was created with)
     *  - status (current system status)
     * @example
     *  let actualQuery = { what: "ever" };
     *  set(this, "name", "testing");
     *  set(this, "selectedCountry", this.store.peekRecord("country", 1));
     *  set(this, "selectedSystemStatus", this.store.peekRecord("systemStatus", 2));
     *  set(this, "selectedSystemType", this.store.peekRecord("systemType", 3));
     *  this.beforeQuery(actualQuery);
     *  console.log(actualQuery); // prints out:
     *  /*
     *  {
     *    what: "ever", // untouched
     *    filter: {
     *      name: "testing",
     *      country: 1
     *      status: 2,
     *      type: 3
     *    }
     *  }
     *  /*
     * @param {object} queryParam basic object composed by the mixin
     * @tested
     */
    beforeQuery: function beforeQuery(queryParam) {
      var query = queryParam;

      if (isNone(query)) {
        return;
      }

      var name = get(this, 'name');
      var country = get(this, 'selectedCountry');
      var status = get(this, 'selectedSystemStatus');
      var type = get(this, 'selectedSystemType');

      if (isPresent(name) && name.trim().length > 0) {
        query.filter = query.filter || {};

        query.filter.name = name.trim();
      }

      if (isPresent(country) && isPresent(get(country, 'id'))) {
        query.filter = query.filter || {};

        query.filter.country = get(country, 'id');
      }

      if (isPresent(status) && isPresent(get(status, 'id'))) {
        query.filter = query.filter || {};

        query.filter.status = get(status, 'id');
      }

      if (isPresent(type) && isPresent(get(type, 'id'))) {
        query.filter = query.filter || {};

        query.filter.type = get(type, 'id');
      }
    }
  });
});