define('xamoom-admin/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = _emberData.default.Model.extend({

    /**
     * Username.
     */
    username: _emberData.default.attr('string'),

    /**
     * Email address.
     */
    email: _emberData.default.attr('string'),

    /**
     * First name.
     */
    firstName: _emberData.default.attr('string'),

    /**
     * Last name.
     */
    lastName: _emberData.default.attr('string'),

    /**
     * Password.
     */
    password: _emberData.default.attr('string'),

    /**
     * A note set upon a user.
     */
    note: _emberData.default.attr('string'),

    /**
     * Relationship to the model: user-role.
     * A user has a user-role.
     * user -> 1 user-role
     */
    role: _emberData.default.belongsTo('userRole', {
      async: false
    }),

    /**
     * Relationship to the model: user-status.
     * A user is in a certain state (status).
     * A user has a status, 1 status at the time.
     * user -> 1 user-status
     */
    status: _emberData.default.belongsTo('userStatus', {
      async: false
    }),

    /**
     * Relationship to the model: system.
     * A user is associated with a system.
     * A user belongs to a system, 1 system at the time.
     * user -> 1 system
     */
    system: _emberData.default.belongsTo('system', {
      async: true
    }),

    /** ******************** STATUSES *************************** */
    /**
     * Determines whether user is an active user.
     * Uses delegation to user-status's isActiveStatus().
     * @returns true if user is an active user, otherwise false.
     * @tested
     */
    isActive: computed('status.isActiveStatus', function () {
      var userStatus = get(this, 'status');

      if (isNone(userStatus)) {
        return false;
      }

      return get(userStatus, 'isActiveStatus');
    }),

    /**
     * Determines whether user is a deactivated user.
     * Uses delegation to user-status's isDeactivatedStatus().
     * @returns true if user is an deactivated user, otherwise false.
     * @tested
     */
    isDeactivated: computed('status.isDeactivatedStatus', function () {
      var userStatus = get(this, 'status');

      if (isNone(userStatus)) {
        return false;
      }

      return get(userStatus, 'isDeactivatedStatus');
    }),

    /**
     * Determines whether user is a deleted user.
     * Uses delegation to user-status's isDeletedStatus().
     * @returns true if user is a deleted user, otherwise false.
     * @tested
     */
    isDeleted: computed('status.isDeletedStatus', function () {
      var userStatus = get(this, 'status');

      if (isNone(userStatus)) {
        return false;
      }

      return get(userStatus, 'isDeletedStatus');
    }),

    /** ******************** ROLES *************************** */
    /**
     * Determines whether user is a xamoom admin user.
     * Uses delegation to user-role's isXamoomAdminRole().
     * @returns true if user is a xamoom admin user, otherwise false.
     * @tested
     */
    isXamoomAdmin: computed('role.isXamoomAdminRole', function () {
      var userRole = get(this, 'role');

      if (isNone(userRole)) {
        return false;
      }

      return get(userRole, 'isXamoomAdminRole');
    }),

    /**
     * Determines whether user is an admin user.
     * Uses delegation to user-role's isAdminRole().
     * @returns true if user is an admin user, otherwise false.
     * @tested
     */
    isAdmin: computed('role.isAdminRole', function () {
      var userRole = get(this, 'role');

      if (isNone(userRole)) {
        return false;
      }

      return get(userRole, 'isAdminRole');
    }),

    /**
     * Determines whether user is a (casual, normal) user.
     * Uses delegation to user-role's isUserRole().
     * @returns true if user is a casual/normal user, otherwise false.
     * @tested
     */
    isUser: computed('role.isUserRole', function () {
      var userRole = get(this, 'role');

      if (isNone(userRole)) {
        return false;
      }

      return get(userRole, 'isUserRole');
    }),

    /**
     * Determines whether user is a xamoom shadow admin.
     * Uses delegation to user-role's isXamoomShadowAdminRole().
     * @returns true if user is a xamoom shadow admin, otherwise false.
     * @tested
     */
    isXamoomShadowAdmin: computed('role.isXamoomShadowAdminRole', function () {
      var userRole = get(this, 'role');

      if (isNone(userRole)) {
        return false;
      }

      return get(userRole, 'isXamoomShadowAdminRole');
    }),

    /**
     * Determines whether user is a restricted user.
     * Uses delegation to user-role's isRestrictedRole().
     * @returns true if user is a restricted user, otherwise false.
     * @tested
     */
    isRestricted: computed('role.isRestrictedRole', function () {
      var userRole = get(this, 'role');

      if (isNone(userRole)) {
        return false;
      }

      return get(userRole, 'isRestrictedRole');
    })
  });
});