define('xamoom-admin/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({

    /**
     * Session service
     * @tested
     */
    session: service(),

    /**
     * Error message
     * {String|Ember.Error}
     * @tested
     */
    error: null
  });
});