define('xamoom-admin/router', ['exports', 'xamoom-admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;


  var Router = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  // eslint-disable-next-line
  Router.map(function () {
    this.route('sessions', function () {
      this.route('login');
      this.route('logout');
      this.route('password', function () {
        this.route('reset');
        this.route('set');
      });
    });
    this.route('systems', function () {
      this.route('new');
      this.route('edit', { path: '/:system_id' });
    });
    this.route('users', function () {
      this.route('new');
      this.route('edit', { path: '/:user_id' });
    });
    this.route('markers', function () {
      this.route('new');
    });
    this.route('profile');
  });

  exports.default = Router;
});