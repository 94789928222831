define('xamoom-admin/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = Route.extend(_applicationRouteMixin.default, {

    actions: {
      /**
       * Sets up 'error' property upon underlying controller.
       * @param {Ember.Error|String} error to be set
       * @override
       * Note: not unit-tested.
       */
      error: function error(_error) {
        set(this.controllerFor('application'), 'error', _error);
      },


      /**
       * Clears controller's 'error' property on transition.
       * @returns {boolean} true
       * Note: not unit-tested.
       */
      willTransition: function willTransition() {
        this._super(arguments);

        if (get(this.controllerFor('application'), 'error')) {
          set(this.controllerFor('application'), 'error', null);
        }

        return true;
      }
    }
  });
});