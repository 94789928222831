define('xamoom-admin/routes/sessions/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'xamoom-admin/mixins/sha512-generator-mixin'], function (exports, _unauthenticatedRouteMixin, _sha512GeneratorMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  var get = Ember.get;
  var getProperties = Ember.getProperties;
  var set = Ember.set;
  var Route = Ember.Route;
  exports.default = Route.extend(_sha512GeneratorMixin.default, _unauthenticatedRouteMixin.default, {

    /**
     * Session service
     */
    session: service('session'),

    actions: {
      /**
       * Delegates to the route's authenticate() method
       * @tested
       */
      authenticate: function authenticate() {
        this.authenticate();
      }
    },

    /**
     * Retrieves username and password, trims them, in case their lenght
     * is appropriate, hashes the password and passes it further to the xamoom authenticator
     * @return {undefined} if username or password is empty
     * @tested
     */
    authenticate: function authenticate() {
      var _this = this;

      var controller = this.controller;

      var _getProperties = getProperties(controller, 'username', 'password'),
          username = _getProperties.username,
          password = _getProperties.password;

      // skip execution if either username or password is empty


      if (isEmpty(username) || isEmpty(password)) {
        set(controller, 'credentialsEmpty', true);
        return;
      }

      username = username.trim();
      password = password.trim();

      if (username.length < 1 || password.length < 1) {
        set(controller, 'credentialsEmpty', true);
        return;
      }

      password = this.generateSHA512(password);

      get(this, 'session').authenticate('authenticator:xamoomCloudAuthenticator', username, password).then(function () {
        _this.transitionTo('systems.index', {
          queryParams: {
            size: 10
          }
        });
      }).catch(function () /* error */{
        set(controller, 'loginFailed', true);
      });
    }
  });
});