define('xamoom-admin/components/dropdowns/dd-countries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var isEmpty = Ember.isEmpty;
  var computed = Ember.computed;
  var get = Ember.get;
  var A = Ember.A;
  var Component = Ember.Component;
  exports.default = Component.extend({

    /**
     * Names of countries to be shown at first place.
     * {Ember.A}
     * @tested
     */
    frequentlySearchedCountries: A(['AUSTRIA', 'GERMANY', 'NETHERLANDS', 'SLOVAKIA', 'SLOVENIA']),

    /**
     * Groups countries into 2 groups according to the probability admin users will need them.
     * The most frequently searched countries would be by now:
     *  - austria,
     *  - germany,
     *  - netherlands,
     *  - slovakia,
     *  - slovenia
     * @tested
     */
    groupedCountries: computed('countries.[]', function () {
      var countries = get(this, 'countries');
      var freqSearchedCountries = get(this, 'frequentlySearchedCountries');

      if (isNone(countries) || isEmpty(countries)) {
        return [];
      }

      var frequentlyUsedCountries = countries.filter(function (country) {
        return freqSearchedCountries.includes(get(country, 'name').toUpperCase());
      });

      var otherCountries = countries.reject(function (country) {
        return freqSearchedCountries.includes(get(country, 'name').toUpperCase());
      });

      return [{
        groupName: 'Frequently used countries',
        options: frequentlyUsedCountries
      }, {
        groupName: 'Other countries',
        options: otherCountries
      }];
    })

  });
});