define('xamoom-admin/adapters/application', ['exports', 'ember-data', 'xamoom-admin/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {

    /**
     * xamoom base url imported from the configuration file.
     * @property host
     * @type String
     * @default ENV.APP.BASE_URL
     * @public
     * @tested
     */
    host: _environment.default.APP.BASE_URL,

    /**
     * Session service
     * @property session
     * @type Service
     * @tested
     */
    session: service(),

    /**
     * xamoom admin namespace imported from configuration
     * @property namespace
     * @type String
     * @default ENV.APP.ADMIN_NAMESPACE
     * @public
     * @tested
     */
    namespace: _environment.default.APP.ADMIN_NAMESPACE,

    /**
     * Authorizer used to set additional headers upon an each request.
     * Explicitly called from DataAdapterMixin.
     * @property authorizer
     * @type String
     * @default 'authorizer:xamoom-cloud-authorizer
     * @public
     * @tested
     */
    authorizer: 'authorizer:xamoom-cloud-authorizer',

    /**
     * Returns true indicating store should always reload a record
     * @method shouldBackgroundReloadRecord
     * @returns {boolean} true, always returns true
     * @override
     * @public
     * @tested
     */
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },


    /**
     * Restructures response received from backend.
     * In case of an error message, creates
     * a new payload object with the attributes:
     * code, message and errors (array of errors)
     * and adds timestamp included in header.
     * sets new header received from backend
     * @method handleResponse
     * @param {number} status code
     * @param {string[]} headers of the response
     * @param {Object} payload - response itself
     * @param {Object} requestData - the original request information
     * @returns {Object} ajusted response
     * @overrides
     * @tested
     */
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var payloadCopy = payload;
      var session = get(this, 'session');

      if (!isEmpty(payload)) {
        if (payloadCopy.error) {
          // restructure
          payloadCopy = {
            code: payloadCopy.error.code,
            message: payloadCopy.error.message,
            errors: payloadCopy.error.errors
          };
        } else {
          session.set('data.token', headers.authorization); // eslint-disable-line ember/use-ember-get-and-set
        }
      } else {
        session.set('data.token', headers.authorization); // eslint-disable-line ember/use-ember-get-and-set
      }

      // run the former logic using updated structure in case of error
      var ret = this._super(status, headers, payloadCopy, requestData);

      if (!isEmpty(ret)) {
        // in case of error include a timestamp, too
        if ('name' in ret && ret.name === 'Error') {
          ret.timestamp = headers.date || headers.Date || new Date().toUTCString();
        }
      }

      if (status === 401 && get(session, 'isAuthenticated')) {
        session.invalidate();
        delete get(this, 'session.data').token;
      }
      return ret;
    }
  });
});