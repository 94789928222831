define('xamoom-admin/controllers/markers/new', ['exports', 'xamoom-admin/mixins/processing-mixin'], function (exports, _processingMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var set = Ember.set;
  var isNone = Ember.isNone;
  var isBlank = Ember.isBlank;
  var typeOf = Ember.typeOf;
  var Controller = Ember.Controller;
  exports.default = Controller.extend(_processingMixin.default, {
    /**
     * Amount of markers to be generated.
     * {Number}
     * @tested
     */
    amount: 3,

    /**
     * The system for which markers should be generated.
     * {System}
     * @tested
     */
    selectedSystem: null,

    actions: {
      /**
       * Returns a promise consumed by ember-power-select offering
       * a collection of systems. A selected system will be used in a user query.
       * Querying for users that belong to the system.
       * In a case of:
       *  - incorrect arguments
       *  - too short (<3) string
       * returns an empty array.
       * @param {string} systemName string used to perform a lookup by 'system name'
       * @returns {Array|Promise} a promise resolving into a collection of systems retrieved from the backend
       * @tested
       */
      searchSystem: function searchSystem(systemName) {
        if (isBlank(systemName) || typeOf(systemName) !== 'string' || systemName.length < 3) {
          return [];
        }

        return this.store.query('system', {
          filter: {
            name: systemName
          }
        }).catch(function (error) {
          console.error(error);
        });
      },


      /**
       * Delegates to doGenerateMarkers() method.
       * @tested
       */
      generateMarkers: function generateMarkers() {
        this.doGenerateMarkers();
      }
    },

    /**
     * In case of correct system and desired markers count calls a backend
     * with a special query parameter causing the backend generating new {count} markers.
     * This process is surrounded by progress-bar initialization and termination.
     * @returns {undefined|Ember.RSVP.Promise} terminates on incorrect arguments or returns a marker promise
     * @tested
     */
    doGenerateMarkers: function doGenerateMarkers() {
      var _this = this;

      var system = get(this, 'selectedSystem');
      var amount = get(this, 'amount');

      if (isNone(system) || isNone(get(system, 'id'))) {
        return;
      }

      if (isNone(amount) || isNaN(amount) || amount < 1) {
        return;
      }

      this.indicateProcessStart();

      return this.store.query('marker', {
        generate: true,
        page: {
          size: amount
        },
        filter: {
          system: get(system, 'id')
        }
      }).then(function (newMarkers) {
        set(_this, 'model', newMarkers);
      }).catch(function (error) {
        console.error(error);
      }).finally(function () {
        _this.indicateProcessCompletion();
      });
    }
  });
});