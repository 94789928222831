define('xamoom-admin/initializers/progress-indicator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'progress-indicator', 'service:progress-indicator');
    application.inject('controller', 'progress-indicator', 'service:progress-indicator');
  }

  exports.default = {
    name: 'progress-indicator',
    initialize: initialize
  };
});