define('xamoom-admin/mixins/model-preloader-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var Mixin = Ember.Mixin;
  var setProperties = Ember.setProperties;
  var get = Ember.get;
  var typeOf = Ember.typeOf;
  var isNone = Ember.isNone;
  exports.default = Mixin.create({

    /**
     * Analyses the store to find out which models are already present in the store
     * and which have not been loaded yet.
     *
     * @example:
     * The store object currently contains 2 country model instances, nothing else.
     * We want to analyse our store to give is loaded/notLoaded instances of models: country, language
     * mapping results as countries, languages.
     * Our modelDescriptors look as follows:
     * [{
     *   modelName: 'country', // check the country model
     *   mappedTo: 'countries' // and give me results using a key 'countries'
     * }, {
     *   modelName: 'language', // check the language model
     *   mappedTo: 'languages'  // and give me results using a key 'languages'
     * }]
     *
     * The function will check the store and return following tuple:
     * { loaded, notLoaded }, where
     * - loaded is an object:
     * {
     *  countries: array of 2 countries which have been already present in the store
     * }
     * - notLoaded is an object:
     * {
     *  languages: an Ember.RSVP.Promise returned by findAll('language')
     *             to retrieve an array of all languages
     * }
     *
     * @param {Object} store instance of the store
     * @param {[{modelName: string, mappedTo: string}]} modelDescriptors
     *        describe which model to analyse and how to map it in the result object
     * @returns {{loaded: {}, notLoaded: {}}} tuple consisting of the loaded and notLoaded models
     * @private
     * @tested
     */
    _analyseStore: function _analyseStore(store, modelDescriptors) {
      var loaded = {};
      var notLoaded = {};

      modelDescriptors.forEach(function (_ref) {
        var modelName = _ref.modelName,
            mappedTo = _ref.mappedTo;

        if (get(store.peekAll(modelName), 'length') > 0) {
          loaded[mappedTo] = store.peekAll(modelName); // model already loaded
        } else {
          notLoaded[mappedTo] = store.findAll(modelName); // model not loaded yet
        }
      });

      return { loaded: loaded, notLoaded: notLoaded };
    },


    /**
     * Finds out which models are loaded and which are not,
     * sets controller properties immediately for already loaded models.
     * It detects unloaded models and sets controller properties for them, too.
     * @returns {*|Promise} nothing or a promise which loads demanded (yet not present) models
     * @throws {Error} in case of not missing or empty 'preloadConfig' property
     * @throws {Error} in case of not missing or empty 'preloadConfig.controllerName' property
     * @throws {Error} in case of not missing or empty 'preloadConfig.preloadedModels' property
     * @throws {Error} in case of not missing or empty 'store' property
     * @throws {Error} in case of not missing or non-function 'this.controllerFor' property
     * @tested
     */
    loadModelsAndSetControllerProperties: function loadModelsAndSetControllerProperties() {
      var preloadConfig = get(this, 'preloadConfig');

      if (isNone(preloadConfig)) {
        throw new Error('Your class is using ModelPreloader but you have not specified its "preloadConfig" property!');
      }

      var controllerName = get(preloadConfig, 'controllerName');

      if (isNone(controllerName)) {
        throw new Error('Your class is using ModelPreloader but "preloadConfig" property hasn\'t specified "controllerName"!');
      }

      var modelDescriptors = get(preloadConfig, 'preloadedModels');

      if (isNone(modelDescriptors)) {
        throw new Error('Your class is using ModelPreloader but you have not specified its "preloadedModels" property!');
      }

      var store = this.store || get(this, 'store');

      if (isNone(store)) {
        throw new Error('ModelPreloader has no access to "store"!');
      }

      if (typeOf(this.controllerFor) !== 'function') {
        throw new Error('ModelPreloader has been applied to a non-route! this.controllerFor() function is missing!');
      }

      var controller = this.controllerFor(controllerName);

      var _analyseStore2 = this._analyseStore(store, modelDescriptors),
          loaded = _analyseStore2.loaded,
          notLoaded = _analyseStore2.notLoaded;

      if (Object.keys(loaded).length > 0) {
        setProperties(controller, loaded);
      }

      if (Object.keys(notLoaded).length > 0) {
        return hash(notLoaded).then(function (models) {
          return setProperties(controller, models);
        });
      }
    }
  });
});