define('xamoom-admin/models/user-status', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = _emberData.default.Model.extend({
    /**
     * User status name.
     */
    name: _emberData.default.attr('string'),

    /**
     * Determines whether user-status is a status: active.
     * @returns true if user-status ID is 0, otherwise false.
     * @tested
     */
    isActiveStatus: computed('id', function () {
      var userStatusId = get(this, 'id');

      if (isNone(userStatusId)) {
        return false;
      }

      return parseInt(userStatusId, 10) === 0;
    }),

    /**
     * Determines whether user-status is a status: deactivated.
     * @returns true if user-status ID is 1, otherwise false.
     * @tested
     */
    isDeactivatedStatus: computed('id', function () {
      var userStatusId = get(this, 'id');

      if (isNone(userStatusId)) {
        return false;
      }

      return parseInt(userStatusId, 10) === 1;
    }),

    /**
     * Determines whether user-status is a status: deleted.
     * @returns true if user-status ID is 2, otherwise false.
     * @tested
     */
    isDeletedStatus: computed('id', function () {
      var userStatusId = get(this, 'id');

      if (isNone(userStatusId)) {
        return false;
      }

      return parseInt(userStatusId, 10) === 2;
    })

  });
});