define('xamoom-admin/components/common-g-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var once = Ember.run.once;
  var isNone = Ember.isNone;
  var isPresent = Ember.isPresent;
  var get = Ember.get;
  var set = Ember.set;
  var Component = Ember.Component;
  exports.default = Component.extend({

    /**
     * Interrupts execution if any of the required parameters are missing
     */
    didInsertElement: function didInsertElement() {
      this._super();

      if (isNone(get(this, 'lat')) || isNone(get(this, 'lng'))) {
        console.error('Map component received either no latitude or longitude parameter.');
        return;
      }

      this.initializeMapWithMarker();
    },


    /**
     * Initializes map, sets its center to the position lat & lng arguments point to,
     * creates a marker at this position, adds a listener to change system's location
     * and marker's position
     * on a click event.
     */
    initializeMapWithMarker: function initializeMapWithMarker() {
      var _this = this;

      var autoComplete = void 0;
      var infoWindow = void 0;

      var actualPosition = new google.maps.LatLng(get(this, 'lat'), get(this, 'lng'));

      var html = this.$();

      var map = new google.maps.Map(html.find('.google-map-container .map')[0], {
        center: actualPosition,
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        zoomControl: true
      });

      var marker = new google.maps.Marker({
        position: actualPosition,
        map: map,
        title: 'System position',
        draggable: true,
        animation: google.maps.Animation.DROP
      });

      google.maps.event.addListener(map, 'click', function (self) {
        return function (event) {
          // check the event argument
          if (isNone(event) || isNone(event.latLng)) {
            return;
          }

          marker.setPosition(new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));

          // update the model
          once(function () {
            set(self, 'lat', event.latLng.lat());
            set(self, 'lng', event.latLng.lng());
          });
        };
      }(this));

      // adding autoComplete
      try {
        autoComplete = new google.maps.places.Autocomplete(html.find('.google-map-container input.geocoder-input')[0]);
        autoComplete.bindTo('bounds', map);
        // set(this, "autoComplete", autoComplete)
        infoWindow = new google.maps.InfoWindow();

        google.maps.event.addListener(autoComplete, 'place_changed', function () {
          var place = autoComplete.getPlace();
          if (isPresent(place.geometry)) {
            // close the infoWindow and hide the marker
            infoWindow.close();
            marker.setVisible(false);

            // If the place has a geometry, then present it on a map.
            if (isPresent(place.geometry.viewport)) {
              map.fitBounds(place.geometry.viewport);
            } else if (isPresent(place.geometry.location)) {
              map.setCenter(place.geometry.location);
              map.setZoom(17); // Why 17? Because it looks good ;)
            }

            marker.setPosition(place.geometry.location);

            // update the model
            once(function () {
              set(_this, 'lat', place.geometry.location.lat());
              set(_this, 'lng', place.geometry.location.lng());
              marker.setVisible(true);
            });

            var address = '';
            if (isPresent(place.address_components)) {
              address = [place.address_components[0] && place.address_components[0].short_name || '', place.address_components[1] && place.address_components[1].short_name || '', place.address_components[2] && place.address_components[2].short_name || ''].join(' ');
            }

            infoWindow.setContent('<div><strong>' + place.name + '</strong><br>' + address);
            infoWindow.open(map, marker);
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  });
});